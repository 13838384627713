import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseStep } from 'libs/components/legacy/wizard/wizard-step/base-step';

import { defaultDocumentsConfig, imagesTypes } from 'tenant-pool/config';
import { AddressFormConfig } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'libs/components';

@Component({
  selector: 'app-profile-dk-one',
  templateUrl: './profile-dk-one.component.html',
  styleUrls: ['./profile-dk-one.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ComponentsModule, TranslateModule]
})
export class ProfileDkOneComponent extends BaseStep implements OnInit {
  public acceptedPhotoTypes = imagesTypes.join(',');
  public acceptedPhotoSize = defaultDocumentsConfig.acceptedDocumentSize;
  @Input() preventPatchingCountryOnInit: boolean;

  public addressFormConfig: AddressFormConfig = {
    showCountry: true,
    prefillFirstCountry: true,
    appendCountryToSearch: true
  };

  public get addressForm() {
    return this.form.get('address') as FormGroup;
  }

  public ngOnInit() {
    super.ngOnInit();
    this.addressFormConfig = {
      ...this.addressFormConfig,
      preventPatchingCountryOnInit: this.preventPatchingCountryOnInit
    };
  }
}
