<app-modal-v2>
  <div class="modal-s new-user-modal">
    <app-modal-content>
      <div class="modal-body new-user-modal__body">
        <div class="new-user-modal__header">
          <app-logo></app-logo>
        </div>

        <div class="new-user-modal__content">
          <div class="new-user-modal__sub-header">
            <img src="/assets/images/icons/icon-checkbox-green.svg" />
            <h3 class="title-xl header__title">
              {{ 'general.welcome_modal.profile_created_header' | translate }}
            </h3>
          </div>

          <p class="new-user-modal__information">
            {{ 'general.welcome_modal.profile_created_message' | translate }}
          </p>
        </div>
      </div>
    </app-modal-content>

    <app-modal-footer>
      <div class="new-user-modal__footer">
        <div class="footer-info-box__container">
          <app-info-box>
            <span class="footer-info__label"
              >{{ 'general.welcome_modal.complete_profile_l' | translate }}
            </span>
            <span class="footer-info__message">{{
              'general.welcome_modal.complete_profile_message' | translate
            }}</span>
          </app-info-box>
        </div>

        <div class="footer-actions">
          <app-button [type]="'light-bordered'" (clickEvent)="close()">{{
            'general.welcome_modal.maybe_later_a' | translate
          }}</app-button>
          <app-button [type]="'primary'" (clickEvent)="dismiss()">{{
            'general.welcome_modal.complete_profile_a' | translate
          }}</app-button>
        </div>
      </div>
    </app-modal-footer>
  </div>
</app-modal-v2>
