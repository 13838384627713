<div class="card h-100 w-100 d-flex" (click)="onGotoMovingChecklist()">
  @if (showNewMarker) {
    <div class="new-marker">{{ 'general.new_l' | translate }}</div>
  }
  <div class="card-header d-flex justify-content-between align-items-center">
    <app-badge class="pr5 d-flex" [color]="'primary-accent-dark'">
      {{ 'badge.moving_checklist_l' | translate }}
    </app-badge>
    @if (doNotAskForMoveInDate) {
      <div class="moving-checklist__days-left">
        @if (getDaysUntilMove() > 0) {
          {{
            'moving-checklist.days_until_moving_l'
              | translate: { days: getDaysUntilMove() }
          }}
        } @else {
          @if (getDaysUntilMove() < 0) {
            {{ 'moving-checklist.past_move_in_l' | translate }}
          }
          @if (getDaysUntilMove() === 0) {
            {{ 'moving-checklist.today_move_in_l' | translate }}
          }
        }
      </div>
    }
  </div>
  <div class="card-body d-flex flex-column ion-justify-content-between">
    <div class="row m-0">
      <div class="col-3 ps-0">
        <svg-icon src="/assets/images/icons/list_illustration.svg"></svg-icon>
      </div>
      <div class="col-9 pe-0 d-flex flex-column justify-content-center">
        <h3 class="card-title">
          @if (progress === 0) {
            {{
              'moving-checklist.title_card_l'
                | translate: { progress: progress }
            }}
          } @else {
            {{
              'moving-checklist.title_progress_l'
                | translate: { progress: progress }
            }}
          }
        </h3>
        <div>
          <ngb-progressbar
            [showValue]="false"
            [value]="progress"
            [max]="100"
          ></ngb-progressbar>
          <div class="d-flex justify-content-between card-body-progress">
            <span>0<span>&#37;</span></span>
            <span>100<span>&#37;</span></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  @if (latestTodo) {
    <div class="card-footer pb0">
      <div class="d-flex justify-content-between align-items-center">
        <strong>{{ 'moving-checklist.next_todo_l' | translate }}</strong>
        <span class="moving-checklist__days-left">
          @if (latestTodo?.days > 0) {
            {{ 'general.still_l' | translate }}
          } @else {
            {{ 'general.before_l' | translate }}
          }
          {{ getNormalizedNumber(latestTodo?.days) }}
          @if (latestTodo?.days > 0) {
            @if (getNormalizedNumber(latestTodo?.days) === 1) {
              {{ 'general.day_l' | translate }}
            } @else {
              {{ 'general.days_l' | translate }}
            }
          } @else {
            @if (getNormalizedNumber(latestTodo?.days) === 1) {
              {{ 'general.day_l' | translate }}
            } @else {
              {{ 'general.day_past_l' | translate }}
            }
          }
        </span>
      </div>
      <div
        class="moving-checklist--todo-title d-flex justify-content-start align-items-center mb20"
      >
        <span class="moving-checklist--checkbox-dummy"></span>
        <p class="moving-checklist__todo-title">
          {{ latestTodo.type + '_TITLE_L' | translate }}
        </p>
      </div>
    </div>
  }
  <div class="row mt-auto p15 pt0">
    <div class="col-12">
      <app-button (clickEvent)="onGotoMovingChecklist()">
        {{ 'moving-checklist.save_settings_a' | translate }}
      </app-button>
    </div>
  </div>
</div>
