import { Component, Input, EventEmitter, Output } from '@angular/core';
import { PropertyApplicationStatus } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'libs/components';

@Component({
  selector: 'app-intent-buttons',
  templateUrl: './intent-buttons.component.html',
  styleUrls: ['./intent-buttons.component.scss'],
  standalone: true,
  imports: [ComponentsModule, TranslateModule]
})
export class IntentButtonsComponent {
  @Input() disabled: boolean;

  @Output() setIntent = new EventEmitter<PropertyApplicationStatus>();

  public onSetIntent(selection: boolean) {
    this.setIntent.emit(
      selection
        ? PropertyApplicationStatus.INTENT
        : PropertyApplicationStatus.NO_INTENT
    );
  }
}
