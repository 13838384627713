import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-cost-details-cell',
  templateUrl: './cost-details-cell.component.html',
  styleUrls: ['./cost-details-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule, CurrencyPipe]
})
export class CostDetailsCellComponent {
  @Input() translationKey: string;
  @Input() textInsteadOfProperty: string;
  @Input() property: number;
  @Input() additionalText: string;
  @Input() showPlusVat = false;
}
