<div class="get-credit-report__container mt20 row mr0 ml0 d-flex">
  <div class="col-12 col-lg-6 get-credit-report__left">
    <div class="get-credit-report__info mb15">
      <h2>{{ 'schufa.get_credit_report_title_l' | translate }}</h2>
      <div class="mb15">
        {{ 'schufa.get_credit_report_desc_l' | translate }}
      </div>
      <app-button
        class="mb15 d-inline get-credit-report__button"
        [type]="'primary'"
        (clickEvent)="onGoToSchufa()"
        >{{ 'schufa.get_credit_report_a' | translate }}</app-button
      >
    </div>
    <div class="get-credit-report__benefits mb40">
      <div class="get-credit-report__cert mb30">
        <h5>
          <svg-icon
            src="/assets/images/icons/icon-certificat.svg"
            [applyClass]="true"
            [svgStyle]="{ 'width.px': 15 }"
            class="main-svg-color"
          ></svg-icon>
          {{ 'schufa.get_credit_report_cert_l' | translate }}
        </h5>
        <span> {{ 'schufa.get_credit_report_cert_desc_l' | translate }} </span>
      </div>
      <div class="get-credit-report__secure_info">
        <h5>
          <svg-icon
            src="/assets/images/icons/lock-icon.svg"
            [applyClass]="true"
            [svgStyle]="{ 'width.px': 15 }"
            class="main-svg-color path rect circle"
          ></svg-icon>
          {{ 'schufa.get_credit_report_secure_info_l' | translate }}
        </h5>
        <span>
          {{ 'schufa.get_credit_report_secure_info_desc_l' | translate }}
        </span>
      </div>
    </div>
    <div class="schufa__logo">
      <img
        src="/assets/images/SCHUFA_4c_A4_in_Kooperation_300dpi.jpg"
        width="140px"
        alt=""
      />
    </div>
  </div>
  <div
    class="col-12 col-lg-6 d-flex justify-content-end get-credit-report__right"
  >
    <img src="/assets/images/schufa_bild.png" alt="" />
  </div>
</div>
