<div class="property__container col-12 p0 row m0 align-items-center">
  <div
    class="col p0 d-flex align-items-center justify-content-between flex-wrap"
  >
    <div class="p0 d-flex">
      <div class="property-image__container">
        @if (picture) {
          <img
            src="{{ picture | appImageSize: 'S' }}"
            alt="{{ property?.name }}"
          />
        }
      </div>
      <div class="property-info__container pl15">
        <div class="property-name">{{ property?.name }}</div>
        <div class="property-address">{{ property?.address | appAddress }}</div>
      </div>
    </div>
    @if (showCustomerInfo) {
      <div class="property__customer-info">
        <app-customer-info-legacy
          [name]="property?.customerName"
          [logo]="property?.customerLogo"
        ></app-customer-info-legacy>
      </div>
    }
  </div>
  @if (activeAppointment) {
    <div
      class="context-menu__button-container col-1 justify-content-end d-flex p0"
    >
      <app-context-menu>
        <div menu-content>
          <app-context-menu-item (clickEvent)="onCancelViewing()">
            {{ 'appointment.cancel_viewing_a' | translate }}
          </app-context-menu-item>
          <app-context-menu-item (clickEvent)="onExportToCalendar()">
            {{ 'property.export_appointment_to_calendar_a' | translate }}
          </app-context-menu-item>
        </div>
      </app-context-menu>
    </div>
  }
  @if (
    activeAppointment?.showContactInformation ||
    activeAppointment?.specialInstructions
  ) {
    <hr class="information-divider" />
  }

  <div class="col-12 mt15 p0 action-menu__container">
    @if (!activeAppointment) {
      <app-button
        [type]="'primary'"
        (clickEvent)="onSelectAppointment()"
        [iconLeft]="'calendar'"
      >
        @if (appointmentsNotDeclined) {
          <span class="pl10">{{
            'appointment.slot_available_l'
              | translate: { value: appointments?.length }
          }}</span>
        } @else {
          {{ 'appointment.appointment_not_fitting' | translate }}
        }
      </app-button>
    } @else {
      <div
        class="appointment-info__container d-flex flex-column align-items-center justify-content-center col-12"
        [ngClass]="{
          canceled: activeAppointment?.state === appointmentState.CANCELED,
          exclusive:
            activeAppointment?.exclusiveAttendees &&
            activeAppointment?.exclusiveAttendees?.length
        }"
      >
        @if (
          activeAppointment?.exclusiveAttendees &&
          activeAppointment?.exclusiveAttendees?.length
        ) {
          <p class="exclusive">
            {{ 'viewings.exclusive_appointment_l' | translate }}
            <app-hint class="pl5">{{
              'viewings.exclusive_appointment_hint_m' | translate
            }}</app-hint>
          </p>
        }
        <div class="d-flex">
          <div class="pr10 d-flex align-items-center">
            <svg-icon
              src="/assets/images/icons/calendar.svg"
              [applyClass]="true"
              [svgStyle]="{ 'width.px': 15 }"
              class="secondary-svg-color path rect"
            ></svg-icon>
            <span class="pl10">{{
              activeAppointment?.date | appDateTime
            }}</span>
          </div>
          <div class="pr10 pl10 d-flex align-items-center">
            <svg-icon
              src="/assets/images/icons/icon-clock.svg"
              [applyClass]="true"
              [svgStyle]="{ 'width.px': 15 }"
              class="secondary-svg-color path circle polyline"
            ></svg-icon>
            <span class="pl10">{{
              activeAppointment?.date
                | appDateTime: { withDate: false, withTime: true }
            }}</span>
          </div>
        </div>
        @if (
          activeAppointment?.showContactInformation ||
          activeAppointment?.specialInstructions
        ) {
          <hr class="m5 p0 col-12" />
        }
        @if (
          activeAppointment?.showContactInformation ||
          activeAppointment?.specialInstructions
        ) {
          <div class="row viewing-item__information-wrapper">
            @if (activeAppointment?.showContactInformation) {
              <div
                class="col-12 col-md-6 viewing-item__contact-information-wrapper"
                [ngClass]="{
                  'has-special-instructions':
                    activeAppointment?.specialInstructions
                }"
              >
                <p class="viewing-item__info-header uppercase-m">
                  {{ 'viewings.contact_information' | translate }}
                </p>
                <span class="d-flex viewing-item__contact-name">{{
                  activeAppointment?.contact?.fullName
                }}</span>
                <span class="d-flex viewing-item__contact-info">{{
                  activeAppointment?.contact?.email
                }}</span>
                <span class="d-flex viewing-item__contact-info">{{
                  activeAppointment?.contact?.phone
                }}</span>
              </div>
            }
            @if (activeAppointment?.specialInstructions) {
              <div
                class="col-12 col-md-6 viewing-item__special-instructions-wrapper"
                [ngClass]="{
                  'has-border':
                    activeAppointment?.showContactInformation &&
                    activeAppointment?.specialInstructions
                }"
              >
                <p class="viewing-item__info-header uppercase-m">
                  {{ 'viewings.special_instructions' | translate }}
                </p>
                <span class="d-flex viewing-item__special_instructions">{{
                  activeAppointment?.specialInstructions
                }}</span>
              </div>
            }
          </div>
        }
      </div>
    }
  </div>
</div>
