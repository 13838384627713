import { RentDepositObjectComponent } from './rent-deposit-object/rent-deposit-object.component';
import { RentDepositPaymentComponent } from './rent-deposit-payment/rent-deposit-payment.component';
import { RentDepositPersonalInformationComponent } from './rent-deposit-personal-information/rent-deposit-personal-information.component';
import { RentDepositSummaryComponent } from './rent-deposit-summary/rent-deposit-summary.component';
import { RentDepositWizardComponent } from './rent-deposit-wizard.component';
import { RentDepositSummaryCardHeaderComponent } from './rent-deposit-summary-card-header/rent-deposit-summary-card-header.component';

export const rentalDepositComponents = [
  RentDepositObjectComponent,
  RentDepositPaymentComponent,
  RentDepositPersonalInformationComponent,
  RentDepositSummaryComponent,
  RentDepositWizardComponent,
  RentDepositSummaryCardHeaderComponent
];
