import { Component, Output, EventEmitter, Input, inject } from '@angular/core';
import { WINDOW_REF } from 'libs/infrastructure';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { ComponentsModule } from 'libs/components';

@Component({
  selector: 'app-moving-auction-card',
  templateUrl: './moving-auction-card.component.html',
  styleUrls: ['./moving-auction-card.component.scss'],
  standalone: true,
  imports: [ComponentsModule, SvgIconComponent, TranslateModule]
})
export class MovingAuctionCardComponent {
  private windowRef = inject(WINDOW_REF);

  @Output() openMovingAuctionWizard = new EventEmitter();
  @Input() showNewMarker = false;

  public onClickMovingAuction() {
    this.openMovingAuctionWizard.emit();
  }

  public onGoToInfo() {
    this.windowRef.open(
      'https://www.mieter.immomio.com/umzugsauktion',
      '_blank'
    );
  }
}
