<form [formGroup]="form" class="review-profile-form">
  <div class="card review-section">
    <div class="card-header">
      <app-badge [color]="'primary-accent-light'" [icon]="'lock-closed'">
        {{ 'general.dk_phase_zero_l' | translate }}
      </app-badge>
      <div>{{ 'general.dk_phase_zero_short_info_l' | translate }}</div>
    </div>
    <div class="card-body">
      <div class="review-section-header">
        {{ 'profile.dk_0_title_l' | translate }}
      </div>
      <div>
        <strong>{{ 'general.name_l' | translate }}</strong>
      </div>
      {{ dkZero | appFullName }}
      @if (hasAnyDocument && hasWbsDocument) {
        <div class="mt20" formGroupName="dkZero">
          <app-form-field>
            <app-form-field-label
              ><strong>{{
                'general.wbs' | translate
              }}</strong></app-form-field-label
            >
            <app-attachments
              [showDownload]="true"
              [showRemove]="false"
              [isDocument]="true"
              [hideFileUpload]="true"
              formControlName="wbsDocument"
              (download)="downloadAttachment($event)"
            ></app-attachments>
          </app-form-field>
        </div>
      }
    </div>
  </div>

  <div class="card review-section">
    <div class="card-header">
      <app-badge [color]="'primary-accent'" [icon]="'lock-closed'">
        {{ 'general.dk_phase_one_l' | translate }}
      </app-badge>
      <div>{{ 'general.dk_phase_one_short_info_l' | translate }}</div>
    </div>
    <div class="card-body">
      <div class="review-section-header">
        {{ 'profile.dk_1_title_l' | translate }}
      </div>
      <div class="personal-info">
        <div class="profile-info__avatar">
          <div>
            <strong>{{ 'general.portrait_l' | translate }}</strong>
          </div>
          <app-avatar
            [imgSrc]="imgSrc"
            name="{{ dkZero | appFullName }}"
            size="large"
          ></app-avatar>
        </div>
        <div class="profile-info d-flex mt20">
          <div class="email col-6 p0">
            <div class="profile-info__email">
              <strong>{{ 'general.email_address_l' | translate }}</strong>
            </div>
            {{ userEmail }}
          </div>
          <div class="phone col-6 p0">
            <div class="profile-info__phone">
              <strong>{{ 'general.phone_l' | translate }}</strong>
            </div>
            {{ dkOne?.phone }}
          </div>
        </div>
        <div class="profile-address mt20">
          <div class="profile-info__address">
            <strong>{{ 'general.address_l' | translate }}</strong>
          </div>
          {{ dkOne?.address | appAddress }}
        </div>
      </div>
    </div>
  </div>

  <div class="card review-section">
    <div class="card-header">
      <app-badge [color]="'primary-accent-dark'" [icon]="'lock-closed'">
        {{ 'general.dk_phase_two_l' | translate }}
      </app-badge>
      <div>{{ 'general.dk_phase_two_short_info_l' | translate }}</div>
    </div>
    <div class="card-body">
      <div class="review-section-header">
        {{ 'profile.dk_2_title_l' | translate }}
      </div>
      <div class="mb20 field-section">
        <div>
          <strong>{{ 'general.birthdate_l' | translate }}</strong>
        </div>
        <div class="field-value">
          {{ dkTwo?.dateOfBirth | appDateTime | appHumanizeBoolean: '-' }}
        </div>
      </div>
      <div class="d-flex row m0 mb20">
        <div class="col-sm-6 p0 field-section">
          <div>
            <strong>{{ 'general.profession_type_l' | translate }}</strong>
          </div>
          @if (dkTwo?.profession?.type) {
            <div class="field-value">
              {{ 'EMPLOYMENTTYPES_' + dkTwo?.profession?.type | translate }}
            </div>
          }
          @if (!dkTwo?.profession?.type) {
            <div class="field-value">-</div>
          }
        </div>
        <div class="col-sm-6 p0 field-section">
          <div>
            <strong>{{ 'general.profession_name_l' | translate }}</strong>
          </div>
          <div class="field-value">
            {{ dkTwo?.profession?.subType | appHumanizeBoolean: '-' }}
          </div>
        </div>
      </div>
      <div class="field-section mb20">
        <div>
          <strong>{{ 'general.monthly_income_l' | translate }}</strong>
        </div>
        <div class="field-value">
          {{
            dkTwo?.profession?.income
              | currency: 'EUR'
              | appHumanizeBoolean: '-'
          }}
        </div>
      </div>
      <div class="d-flex row m0 mb20">
        <div class="col-sm-4 pl0 field-section">
          <div>
            <strong>{{ 'general.household_type_l' | translate }}</strong>
          </div>
          @if (dkTwo?.householdType) {
            <div class="field-value">
              {{ 'HOUSEHOLDTYPES_' + dkTwo?.householdType | translate }}
            </div>
          }
          @if (!dkTwo?.householdType) {
            <div class="field-value">-</div>
          }
        </div>
        <div class="col-sm-4 pl0 field-section">
          <div>
            <strong>{{ 'general.residents_count_l' | translate }}</strong>
          </div>
          <div class="field-value">
            {{ dkTwo?.residents | appHumanizeBoolean: '-' }}
          </div>
        </div>
        <div class="col-sm-4 pl0 field-section">
          <div>
            <strong>{{ 'general.animals_l' | translate }}</strong>
          </div>
          <div class="field-value">
            {{ dkTwo?.animals | appHumanizeBoolean: '-' }}
          </div>
        </div>
      </div>
      <div class="field-section mb20">
        <div>
          <strong>{{ 'general.guarantor_available_l' | translate }}</strong>
        </div>
        <div class="field-value">
          {{ dkTwo?.guarantorExist | appHumanizeBoolean: '-' }}
        </div>
      </div>
      <div class="field-section">
        <div>
          <strong>{{ 'general.short_bio_l' | translate }}</strong>
        </div>
        <div class="field-value">
          {{ dkTwo?.furtherInformation | appHumanizeBoolean: '-' }}
        </div>
      </div>
    </div>
  </div>

  <div class="card review-section">
    <div class="card-header">
      <app-badge [color]="'secondary-accent'" [icon]="'lock-closed'">
        {{ 'general.dk_phase_three_l' | translate }}
      </app-badge>
      <div>{{ 'general.dk_phase_three_short_info_l' | translate }}</div>
    </div>
    <div class="card-body">
      <div class="review-section-header">
        {{ 'profile.dk_3_title_l' | translate }}
      </div>
      @if (hasIncomeProofDocument) {
        <div class="mb12" formGroupName="dkThree">
          <app-form-field>
            <app-form-field-label
              ><strong>{{
                'general.proof_of_income_l' | translate
              }}</strong></app-form-field-label
            >
            <app-attachments
              [showDownload]="true"
              [showRemove]="false"
              [isDocument]="true"
              [hideFileUpload]="true"
              [multiple]="true"
              formControlName="incomeProof"
              (download)="downloadAttachment($event)"
            ></app-attachments>
          </app-form-field>
        </div>
      }
      @if (hasCreditReportDocument) {
        <div class="mb12" formGroupName="dkThree">
          <app-form-field>
            <app-form-field-label
              ><strong>{{
                'general.credit_score_l' | translate
              }}</strong></app-form-field-label
            >
            <app-attachments
              [showDownload]="true"
              [showRemove]="false"
              [isDocument]="true"
              [hideFileUpload]="true"
              formControlName="creditScore"
              (download)="downloadAttachment($event)"
            ></app-attachments>
          </app-form-field>
        </div>
      }
      @if (hasOtherDocuments) {
        <div class="mb12" formGroupName="dkThree">
          <app-form-field>
            <app-form-field-label
              ><strong>{{
                'general.other_documents_l' | translate
              }}</strong></app-form-field-label
            >
            <app-attachments
              [showDownload]="true"
              [showRemove]="false"
              [isDocument]="true"
              [multiple]="true"
              [hideFileUpload]="true"
              formControlName="otherDocuments"
              (download)="downloadAttachment($event)"
            ></app-attachments>
          </app-form-field>
        </div>
      }
    </div>
  </div>

  @if (!guestMode) {
    <div class="search-profile mt20">
      <div class="uppercase-m semibold search-profile-section-header">
        {{ 'profile.property_search_profile_l' | translate }}
      </div>
      @for (searchProfile of searchProfiles; track searchProfile.id) {
        <div class="search-profile-section mb16">
          <app-search-profile-info
            [searchProfile]="searchProfile"
            [small]="true"
            (editProfile)="onEditSearchProfile($event)"
            (deleteProfile)="onDeleteSearchProfile($event)"
            (toggleProfile)="onToggleSearchProfile($event)"
          ></app-search-profile-info>
        </div>
      }
    </div>
    <div class="mt16 mb32">
      <app-button
        (clickEvent)="onAddSearchProfile()"
        [type]="'light-bordered'"
        [iconLeft]="'add'"
      >
        {{ 'search_profile.add_search_profile_a' | translate }}
      </app-button>
    </div>
  }

  <div class="mb20">
    <app-info-box
      >{{ 'profile.credit_report_confirmation' | translate }}
    </app-info-box>
  </div>
</form>
