@if (propertyMatch || isApplyPage) {
  <div
    class="property-expose container mb20 pb15 pl0 pr0"
    [ngClass]="{
      mt0: isApplyPage
    }"
  >
    <div class="property-expose__header">
      <div
        class="property-expose__top-menu d-flex align-items-center ios-padding-top"
        [ngClass]="{
          'justify-content-between': !isApplyPage,
          'justify-content-end': isApplyPage
        }"
      >
        @if (!isApplyPage) {
          <app-button
            [class]="'property-expose__back-button'"
            [type]="'context-menu'"
            [iconLeft]="'arrow-left'"
            [elevation]="1"
            (clickEvent)="back()"
          >
          </app-button>
        }
        @if (isApplyPage && property.applyLink) {
          <app-button
            [class]="'property-expose__share-button'"
            [type]="'context-menu'"
            [iconLeft]="'share'"
            [elevation]="1"
            (clickEvent)="onShareProperty(property.applyLink)"
          >
          </app-button>
        }
      </div>
    </div>
    <div
      [class.no-header-image]="
        !property?.data?.attachments ||
        property?.data?.attachments?.length === 0
      "
    >
      <app-property-card-tenant
        [propertyMatch]="propertyMatch"
        [property]="property"
        [pageView]="true"
        [enableImageGallery]="true"
        [isApplyPage]="isApplyPage"
      >
      </app-property-card-tenant>
    </div>
    <div class="property-expose__body pb-3">
      <hr class="property-expose__divider mt0 mb0" />
      @if (!isApplyPage && hasDocuments) {
        <div class="property-expose__documents mt30">
          <div class="title-m mb8">{{ 'general.documents_l' | translate }}</div>
          <div class="row d-flex">
            <div class="col-12">
              <app-attachments-list-v2>
                <div class="d-flex row col-12">
                  @if (floorPlan) {
                    <div class="col-12 col-md-6 mb10 pl0 pr5">
                      <app-attachment-item-v2
                        [attachment]="floorPlan"
                        [isDocument]="true"
                        [showRemove]="false"
                        [directDownload]="true"
                        (download)="onDownloadDocument(floorPlan)"
                      >
                      </app-attachment-item-v2>
                    </div>
                  }
                  @for (
                    attachment of getOtherDocuments(property?.data?.documents);
                    track attachment.identifier
                  ) {
                    <div class="col-12 col-md-6 mb10 pl0 pr5">
                      <app-attachment-item-v2
                        [attachment]="attachment"
                        [isDocument]="true"
                        [showRemove]="false"
                        [directDownload]="true"
                        (download)="onDownloadDocument(attachment)"
                      >
                      </app-attachment-item-v2>
                    </div>
                  }
                </div>
              </app-attachments-list-v2>
            </div>
          </div>
        </div>
      }
      @if (hasAnyDescription) {
        <div
          class="property-expose__description mt30"
          [class.property-expose__description--collapsed]="!descriptionExpanded"
          (click)="showMore()"
        >
          <div>
            @if (getObjectDescription) {
              <div class="col-12 col-md-9 p0">
                <h2 class="title-m">
                  {{ 'general.property_description_l' | translate }}
                </h2>
                <p class="m0">{{ getObjectDescription }}</p>
              </div>
            }
            @if (getFurnishingDescription) {
              <div class="mt15 col-12 col-md-9 p0">
                <h2 class="title-m">
                  {{ 'general.property_description.furnishing_l' | translate }}
                </h2>
                <p class="m0">{{ getFurnishingDescription }}</p>
              </div>
            }
            @if (getObjectLocationText) {
              <div class="mt15 col-12 col-md-9 p0">
                <h2 class="title-m">
                  {{ 'general.property_description.location_l' | translate }}
                </h2>
                <p class="m0">{{ getObjectLocationText }}</p>
              </div>
            }
            @if (getObjectMiscellaneousText) {
              <div class="mt15 col-12 col-md-9 p0">
                <h2 class="title-m">
                  {{
                    'general.property_description.miscellaneous_l' | translate
                  }}
                </h2>
                <p class="m0">{{ getObjectMiscellaneousText }}</p>
              </div>
            }
          </div>
        </div>
      }
      @if (!descriptionExpanded && hasAnyDescription) {
        <app-button [type]="'link'" (clickEvent)="showMore()">
          {{ 'general.show_more_l' | translate }}
        </app-button>
      }
      <div class="property-expose__details card-columns mt30">
        <div class="card">
          <h2 class="title-m">{{ 'general.costs_l' | translate }}</h2>
          <div>
            @if (!isSales(property?.marketingType)) {
              <app-cost-details-cell
                [translationKey]="'general.rent_l'"
                [property]="property?.data?.basePrice"
                [showPlusVat]="
                  isCommercial(property) &&
                  property?.data?.commercialData?.vatNotIncludedInPrice
                "
              >
                <svg-icon
                  src="/assets/images/icons/icon-money.svg"
                  [applyClass]="true"
                  [svgStyle]="{ 'width.px': 15 }"
                  class="primary-text-svg-color rect line polyline path"
                ></svg-icon>
              </app-cost-details-cell>
              @if (
                isCommercial(property) &&
                isNotNullAndUndefined(
                  property?.data?.commercialData?.pricePerSquareMeter
                ) &&
                !isSales(property?.marketingType)
              ) {
                <app-cost-details-cell
                  [translationKey]="'general.price_per_square_meter_l'"
                  [property]="
                    property?.data?.commercialData?.pricePerSquareMeter
                  "
                >
                  <svg-icon
                    src="/assets/images/icons/icon-money.svg"
                    [applyClass]="true"
                    [svgStyle]="{ 'width.px': 15 }"
                    class="primary-text-svg-color rect line polyline path"
                  ></svg-icon>
                </app-cost-details-cell>
              }
              <!-- Courtage -->
              @if (
                isNotNullAndUndefined(property?.data?.courtageData?.courtage) &&
                !property?.data?.courtageData?.courtageInPercent &&
                isCommercial(property)
              ) {
                <app-cost-details-cell
                  [translationKey]="'general.courtage_l'"
                  [property]="property?.data?.courtageData?.courtage"
                  [additionalText]="
                    property?.data?.courtageData?.plusVAT
                      ? 'courtage.plus_vat.included'
                      : ''
                  "
                >
                  <svg-icon
                    src="/assets/images/icons/icon-additional-costs.svg"
                    [applyClass]="true"
                    [svgStyle]="{ 'width.px': 15 }"
                    class="primary-text-svg-color circle path"
                  ></svg-icon>
                </app-cost-details-cell>
              }
              @if (
                property?.data?.courtageData?.courtageInPercent &&
                isCommercial(property)
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-money.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.courtage_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    <span>{{ property?.data?.courtageData?.courtage }}%</span>
                    @if (property?.data?.courtageData?.plusVAT) {
                      <span class="ms-1">{{
                        'courtage.plus_vat.included' | translate
                      }}</span>
                    }
                  </dd>
                </dl>
              }
              @if (
                property?.data?.courtageData?.text && isCommercial(property)
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-money.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.courtage_info_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    <span>{{ property?.data?.courtageData?.text }}</span>
                  </dd>
                </dl>
              }
              <!-- Courtage End -->
              @if (!isGarage(property)) {
                <app-cost-details-cell
                  [translationKey]="'general.additional_costs_l'"
                  [property]="property?.data?.serviceCharge"
                  [showPlusVat]="
                    isCommercial(property) &&
                    property?.data?.commercialData?.vatNotIncludedInPrice
                  "
                >
                  <svg-icon
                    src="/assets/images/icons/icon-additional-costs.svg"
                    [applyClass]="true"
                    [svgStyle]="{ 'width.px': 15 }"
                    class="primary-text-svg-color circle path"
                  ></svg-icon>
                </app-cost-details-cell>
              }
              <app-cost-details-cell
                [translationKey]="'general.other_costs_l'"
                [property]="property?.data?.otherCosts"
                [showPlusVat]="
                  isCommercial(property) &&
                  property?.data?.commercialData?.vatNotIncludedInPrice
                "
              >
                <svg-icon
                  src="/assets/images/icons/icon-additional-costs.svg"
                  [applyClass]="true"
                  [svgStyle]="{ 'width.px': 15 }"
                  class="primary-text-svg-color circle path"
                ></svg-icon>
              </app-cost-details-cell>
              @if (isFlat(property) || isCommercial(property)) {
                <app-cost-details-cell
                  [translationKey]="'general.deposit_l'"
                  [property]="property?.data?.bailment"
                >
                  <svg-icon
                    src="/assets/images/icons/icon-coins.svg"
                    [applyClass]="true"
                    [svgStyle]="{ 'width.px': 15 }"
                    class="primary-text-svg-color rect line polyline path"
                  ></svg-icon>
                </app-cost-details-cell>
              }
              @if (
                isNotNullAndUndefined(property?.entryPrice) &&
                !property?.settings?.disableEntranceFee
              ) {
                <app-cost-details-cell
                  [translationKey]="'general.entry_price_l'"
                  [property]="property?.entryPrice"
                >
                  <svg-icon
                    src="/assets/images/icons/icon-entry-ticket.svg"
                    [applyClass]="true"
                    [svgStyle]="{ 'width.px': 15 }"
                    class="primary-text-svg-color rect line polyline path"
                  ></svg-icon>
                </app-cost-details-cell>
              }
              @if (isFlat(property) || isCommercial(property)) {
                <app-cost-details-cell
                  [translationKey]="'general.heating_costs_l'"
                  [property]="property?.data?.heatingCost"
                  [additionalText]="
                    property?.data?.heatingCostIncluded
                      ? 'general.included_to_additionCosts_l'
                      : ''
                  "
                  [showPlusVat]="
                    isCommercial(property) &&
                    property?.data?.commercialData?.vatNotIncludedInPrice
                  "
                >
                  <svg-icon
                    src="/assets/images/icons/icon-fire.svg"
                    [applyClass]="true"
                    [svgStyle]="{ 'width.px': 15 }"
                    class="primary-text-svg-color rect line polyline path"
                  ></svg-icon>
                </app-cost-details-cell>
              }
              <app-cost-details-cell
                [translationKey]="'general.total_cost_l'"
                [property]="property?.data?.totalRentGross"
                [showPlusVat]="
                  isCommercial(property) &&
                  property?.data?.commercialData?.vatNotIncludedInPrice
                "
              >
                <svg-icon
                  src="/assets/images/icons/icon-moneybag.svg"
                  [applyClass]="true"
                  [svgStyle]="{ 'width.px': 15 }"
                  class="primary-text-svg-color rect line polyline path"
                ></svg-icon>
              </app-cost-details-cell>
            } @else {
              <app-cost-details-cell
                [translationKey]="'general.purchase_price_l'"
                [property]="property?.data?.salesData?.price"
                [showPlusVat]="
                  isCommercial(property) &&
                  property?.data?.commercialData?.vatNotIncludedInPrice
                "
              >
                <svg-icon
                  src="/assets/images/icons/icon-money.svg"
                  [applyClass]="true"
                  [svgStyle]="{ 'width.px': 15 }"
                  class="primary-text-svg-color rect line polyline path"
                ></svg-icon>
              </app-cost-details-cell>
              @if (
                isNotNullAndUndefined(property?.data?.courtageData?.courtage) &&
                !property?.data?.courtageData?.courtageInPercent
              ) {
                <app-cost-details-cell
                  [translationKey]="'general.courtage_l'"
                  [property]="property?.data?.courtageData?.courtage"
                  [additionalText]="
                    property?.data?.courtageData?.plusVAT
                      ? 'courtage.plus_vat.included'
                      : ''
                  "
                >
                  <svg-icon
                    src="/assets/images/icons/icon-additional-costs.svg"
                    [applyClass]="true"
                    [svgStyle]="{ 'width.px': 15 }"
                    class="primary-text-svg-color circle path"
                  ></svg-icon>
                </app-cost-details-cell>
              }
              @if (property?.data?.courtageData?.courtageInPercent) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-money.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.courtage_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data d-flex flex-column"
                  >
                    <span>{{ property?.data?.courtageData?.courtage }}%</span>
                  </dd>
                </dl>
              }
              @if (property?.data?.courtageData?.text) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-money.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.courtage_info_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    <span>{{ property?.data?.courtageData?.text }}</span>
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(property?.data?.salesData?.serviceCharge)
              ) {
                <app-cost-details-cell
                  [translationKey]="'property.living_price.l'"
                  [property]="property?.data?.salesData?.serviceCharge"
                  [showPlusVat]="
                    isCommercial(property) &&
                    property?.data?.commercialData?.vatNotIncludedInPrice
                  "
                >
                  <svg-icon
                    src="/assets/images/icons/icon-additional-costs.svg"
                    [applyClass]="true"
                    [svgStyle]="{ 'width.px': 15 }"
                    class="primary-text-svg-color circle path"
                  ></svg-icon>
                </app-cost-details-cell>
              }
            }
            @if (
              (isFlat(property) || isCommercial(property)) &&
              (property?.data?.totalParkingPrice ||
                property?.data?.totalParkingPurchasePrice)
            ) {
              <app-cost-details-cell
                [translationKey]="'general.parking_costs_l'"
                [property]="
                  property?.data?.totalParkingPrice ||
                  property?.data?.totalParkingPurchasePrice
                "
              >
                <svg-icon
                  src="/assets/images/icons/icon-garage.svg"
                  [applyClass]="true"
                  [svgStyle]="{ 'width.px': 15 }"
                  class="primary-text-svg-color rect line polyline path"
                ></svg-icon>
              </app-cost-details-cell>
            }
          </div>
        </div>
        @if (isFlat(property) || isCommercial(property)) {
          <div class="card" [ngClass]="{ 'u-hide': !showEnergyDetailsList }">
            <h2 class="title-m">
              {{ 'general.property_energy_l' | translate }}
            </h2>
            <div #energyDetailsList>
              @if (isNotNullAndUndefined(property?.data?.heater)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-fire.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.apartment_heater_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      'HEATERFIRINGTYPES_' + property?.data?.heater | translate
                    }}
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(
                  property?.data?.energyCertificate?.creationDate
                )
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-calendar.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.apartment_energy_cert_year_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      'CERTIFICATECREATIONDATES_' +
                        property?.data?.energyCertificate?.creationDate
                        | translate
                    }}
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(
                  property?.data?.energyCertificate?.energyCertificateType
                )
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-document.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.apartment_energy_cert_type_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      'ENERGYCERTIFICATETYPES_' +
                        property?.data?.energyCertificate?.energyCertificateType
                        | translate
                    }}
                  </dd>
                </dl>
              }
              @if (isNotNullAndUndefined(getEnergyEfficiencyClass)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-energy-efficiency-class.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.apartment_energy_class_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ getEnergyEfficiencyClass | translate }}
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(
                  property?.data?.energyCertificate?.primaryEnergyProvider
                )
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-electricity.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{
                        'general.apartment_primary_energy_provider_l'
                          | translate
                      }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      'HEATERTYPES_' +
                        property?.data?.energyCertificate?.primaryEnergyProvider
                        | translate
                    }}
                  </dd>
                </dl>
              }
              @if (isNotNullAndUndefined(getEnergyConsumption)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-energy-requirement.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.apartment_energy_consumption_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ getEnergyConsumption }}
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(
                  property?.data?.energyCertificate?.primaryEnergyConsumption
                )
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-primary-energy-demand.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{
                        'general.apartment_primary_energy_consumption_l'
                          | translate
                      }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      property?.data?.energyCertificate
                        ?.primaryEnergyConsumption
                    }}
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(
                  property?.data?.energyCertificate?.austrianEnergyCertificate
                    ?.dateOfExpiry
                )
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-calendar.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{
                        'general.apartment_energy_date_of_expiry_l' | translate
                      }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      property?.data?.energyCertificate
                        ?.austrianEnergyCertificate?.dateOfExpiry | appDateTime
                    }}
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(
                  property?.data?.energyCertificate?.austrianEnergyCertificate
                    ?.heatingEnergyDemand
                )
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-energy-requirement.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{
                        'general.apartment_energy_heating_energy_demand_l'
                          | translate
                      }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      property?.data?.energyCertificate
                        ?.austrianEnergyCertificate?.heatingEnergyDemand
                    }}
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(
                  property?.data?.energyCertificate?.austrianEnergyCertificate
                    ?.heatingEnergyDemandClass
                )
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-energy-efficiency-class.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{
                        'general.apartment_energy_heating_energy_demand_class_l'
                          | translate
                      }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      property?.data?.energyCertificate
                        ?.austrianEnergyCertificate?.heatingEnergyDemandClass
                        | appEnergyClass
                    }}
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(
                  property?.data?.energyCertificate?.austrianEnergyCertificate
                    ?.overallEnergyEfficiencyFactor
                )
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-primary-energy-demand.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{
                        'general.apartment_energy_efficiency_factor_l'
                          | translate
                      }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      property?.data?.energyCertificate
                        ?.austrianEnergyCertificate
                        ?.overallEnergyEfficiencyFactor
                    }}
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(
                  property?.data?.energyCertificate?.austrianEnergyCertificate
                    ?.overallEnergyEfficiencyFactorClass
                )
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-energy-efficiency-class.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{
                        'general.apartment_energy_efficiency_factor_class_l'
                          | translate
                      }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      property?.data?.energyCertificate
                        ?.austrianEnergyCertificate
                        ?.overallEnergyEfficiencyFactorClass | appEnergyClass
                    }}
                  </dd>
                </dl>
              }
            </div>
          </div>
        }
        <div class="card">
          <h2 class="title-m">
            {{ 'general.property_details_l' | translate }}
          </h2>
          <div>
            @if (!isCommercial(property)) {
              <dl class="property-expose__details-row">
                <dt
                  class="col-8 property-expose__details-cell property-expose__details-cell--title"
                >
                  <svg-icon
                    src="/assets/images/icons/icon-object.svg"
                    [applyClass]="true"
                    [svgStyle]="{ 'width.px': 15 }"
                    class="primary-text-svg-color rect line polyline path"
                  ></svg-icon>
                  <span class="u-overflow-hidden">
                    {{ 'general.apartment_objectType_l' | translate }}
                  </span>
                </dt>
                <dd
                  class="col-4 property-expose__details-cell property-expose__details-cell--data"
                >
                  {{ 'FLATTYPES_' + property?.data?.objectType | translate }}
                </dd>
              </dl>
            } @else {
              <dl class="property-expose__details-row">
                <dt
                  class="col-8 property-expose__details-cell property-expose__details-cell--title"
                >
                  <svg-icon
                    src="/assets/images/icons/icon-object.svg"
                    [applyClass]="true"
                    [svgStyle]="{ 'width.px': 15 }"
                    class="primary-text-svg-color rect line polyline path"
                  ></svg-icon>
                  <span class="u-overflow-hidden">
                    {{ 'property.commercial_type_l' | translate }}
                  </span>
                </dt>
                <dd
                  class="col-4 property-expose__details-cell property-expose__details-cell--data"
                >
                  {{
                    'commercial_type.' +
                      property?.data?.commercialData?.commercialType?.toLowerCase() +
                      '_l' | translate
                  }}
                </dd>
              </dl>
              <dl class="property-expose__details-row">
                <dt
                  class="col-8 property-expose__details-cell property-expose__details-cell--title"
                >
                  <svg-icon
                    src="/assets/images/icons/icon-object.svg"
                    [applyClass]="true"
                    [svgStyle]="{ 'width.px': 15 }"
                    class="primary-text-svg-color rect line polyline path"
                  ></svg-icon>
                  <span class="u-overflow-hidden">
                    {{ 'property.commercial_sub_type_l' | translate }}
                  </span>
                </dt>
                <dd
                  class="col-4 property-expose__details-cell property-expose__details-cell--data"
                >
                  {{
                    'commercial_sub_type.' +
                      property?.data?.commercialData?.commercialSubType?.toLowerCase() +
                      '_l' | translate
                  }}
                </dd>
              </dl>
            }
            @if (
              (isFlat(property) || isCommercial(property)) &&
              isNotNullAndUndefined(property?.data?.buildingCondition)
            ) {
              <dl class="property-expose__details-row">
                <dt
                  class="col-8 property-expose__details-cell property-expose__details-cell--title"
                >
                  <svg-icon
                    src="/assets/images/icons/icon-paint-roller.svg"
                    [applyClass]="true"
                    [svgStyle]="{ 'width.px': 15 }"
                    class="primary-text-svg-color rect line polyline path"
                  ></svg-icon>
                  <span class="u-overflow-hidden">
                    {{ 'general.condition_of_apartment_l' | translate }}
                  </span>
                </dt>
                <dd
                  class="col-4 property-expose__details-cell property-expose__details-cell--data"
                >
                  {{
                    'BUILDINGCONDITIONTYPES_' +
                      property?.data?.buildingCondition | translate
                  }}
                </dd>
              </dl>
            }
            @if (isNotNullAndUndefined(property?.data?.furnishingType)) {
              <dl class="property-expose__details-row">
                <dt
                  class="col-8 property-expose__details-cell property-expose__details-cell--title"
                >
                  <svg-icon
                    src="/assets/images/icons/icon-chair.svg"
                    [applyClass]="true"
                    [svgStyle]="{ 'width.px': 15 }"
                    class="primary-text-svg-color rect line polyline path"
                  ></svg-icon>
                  <span class="u-overflow-hidden">
                    {{ 'general.furnishing_type_l' | translate }}
                  </span>
                </dt>
                <dd
                  class="col-4 property-expose__details-cell property-expose__details-cell--data"
                >
                  {{
                    'furnishing.' + property?.data?.furnishingType | translate
                  }}
                </dd>
              </dl>
            }
            @if (isNotNullAndUndefined(property?.data?.floor)) {
              <dl class="property-expose__details-row">
                <dt
                  class="col-8 property-expose__details-cell property-expose__details-cell--title"
                >
                  <svg-icon
                    src="/assets/images/icons/icon-story.svg"
                    [applyClass]="true"
                    [svgStyle]="{ 'width.px': 15 }"
                    class="primary-text-svg-color rect line polyline path"
                  ></svg-icon>
                  <span class="u-overflow-hidden">
                    {{ 'general.floor_number_l' | translate }}
                  </span>
                </dt>
                <dd
                  class="col-4 property-expose__details-cell property-expose__details-cell--data"
                >
                  {{ property?.data?.floor | appFloor }}
                </dd>
              </dl>
            }
            @if (isNotNullAndUndefined(property?.data?.ground)) {
              <dl class="property-expose__details-row">
                <dt
                  class="col-8 property-expose__details-cell property-expose__details-cell--title"
                >
                  <svg-icon
                    src="/assets/images/icons/icon-floor.svg"
                    [applyClass]="true"
                    [svgStyle]="{ 'width.px': 15 }"
                    class="primary-text-svg-color rect line polyline path"
                  ></svg-icon>
                  <span class="u-overflow-hidden">
                    {{ 'general.ground_type_l' | translate }}
                  </span>
                </dt>
                <dd
                  class="col-4 property-expose__details-cell property-expose__details-cell--data"
                >
                  {{ 'GROUNDTYPES_' + property?.data?.ground | translate }}
                </dd>
              </dl>
            }
            @if (isNotNullAndUndefined(property?.data?.barrierFree)) {
              <dl class="property-expose__details-row">
                <dt
                  class="col-8 property-expose__details-cell property-expose__details-cell--title"
                >
                  <svg-icon
                    src="/assets/images/icons/icon-barrier-free.svg"
                    [applyClass]="true"
                    [svgStyle]="{ 'width.px': 15 }"
                    class="primary-text-svg-color rect line polyline path"
                  ></svg-icon>
                  <span class="u-overflow-hidden">
                    {{ 'general.apartment_barrier_free_l' | translate }}
                  </span>
                </dt>
                <dd
                  class="col-4 property-expose__details-cell property-expose__details-cell--data"
                >
                  {{ property?.data?.barrierFree | appHumanizeBoolean }}
                </dd>
              </dl>
            }
            @if (
              isNotNullAndUndefined(
                property?.data?.availableFrom?.dateAvailable ||
                  property?.data?.availableFrom?.stringAvailable
              )
            ) {
              <dl class="property-expose__details-row">
                <dt
                  class="col-8 property-expose__details-cell property-expose__details-cell--title"
                >
                  <svg-icon
                    src="/assets/images/icons/icon-calendar.svg"
                    [applyClass]="true"
                    [svgStyle]="{ 'width.px': 15 }"
                    class="primary-text-svg-color rect line polyline path"
                  ></svg-icon>
                  <span class="u-overflow-hidden">
                    {{ 'general.apartment_availableFrom_l' | translate }}
                  </span>
                </dt>
                <dd
                  class="col-4 property-expose__details-cell property-expose__details-cell--data"
                >
                  {{
                    (property?.data?.availableFrom?.dateAvailable
                      | appDateTime) ||
                      property?.data?.availableFrom?.stringAvailable
                  }}
                </dd>
              </dl>
            }
            @if (isNotNullAndUndefined(property?.data?.flatType)) {
              <dl class="property-expose__details-row">
                <dt
                  class="col-8 property-expose__details-cell property-expose__details-cell--title"
                >
                  <svg-icon
                    src="/assets/images/icons/icon-appartment-type.svg"
                    [applyClass]="true"
                    [svgStyle]="{ 'width.px': 15 }"
                    class="primary-text-svg-color rect line polyline path"
                  ></svg-icon>
                  <span class="u-overflow-hidden">
                    {{ 'general.type_of_accomodation_l' | translate }}
                  </span>
                </dt>
                <dd
                  class="col-4 property-expose__details-cell property-expose__details-cell--data"
                >
                  {{ 'FLATTYPES_' + property?.data?.flatType | translate }}
                </dd>
              </dl>
            }
            @if (isNotNullAndUndefined(property?.data?.constructionYear)) {
              <dl class="property-expose__details-row">
                <dt
                  class="col-8 property-expose__details-cell property-expose__details-cell--title"
                >
                  <svg-icon
                    src="/assets/images/icons/icon-construction-year.svg"
                    [applyClass]="true"
                    [svgStyle]="{ 'width.px': 15 }"
                    class="primary-text-svg-color rect line polyline path"
                  ></svg-icon>
                  <span class="u-overflow-hidden">
                    {{ 'general.apartment_constructionYear_l' | translate }}
                  </span>
                </dt>
                <dd
                  class="col-4 property-expose__details-cell property-expose__details-cell--data"
                >
                  {{ property?.data?.constructionYear }}
                </dd>
              </dl>
            }
            @if (
              !isGarage(property) &&
              isNotNullAndUndefined(property?.data?.basementSize)
            ) {
              <dl class="property-expose__details-row">
                <dt
                  class="col-8 property-expose__details-cell property-expose__details-cell--title"
                >
                  <svg-icon
                    src="/assets/images/icons/icon-basement.svg"
                    [applyClass]="true"
                    [svgStyle]="{ 'width.px': 15 }"
                    class="primary-text-svg-color rect line polyline path"
                  ></svg-icon>
                  <span class="u-overflow-hidden">
                    {{ 'general.basement_area_l' | translate }}
                  </span>
                </dt>
                <dd
                  class="col-4 property-expose__details-cell property-expose__details-cell--data"
                >
                  {{ property?.data?.basementSize }} m<sup>2</sup>
                </dd>
              </dl>
            }
            @if (isNotNullAndUndefined(property?.data?.numberOfFloors)) {
              <dl class="property-expose__details-row">
                <dt
                  class="col-8 property-expose__details-cell property-expose__details-cell--title"
                >
                  <svg-icon
                    src="/assets/images/icons/icon-etage.svg"
                    [applyClass]="true"
                    [svgStyle]="{ 'width.px': 15 }"
                    class="primary-text-svg-color rect line polyline path"
                  ></svg-icon>
                  <span class="u-overflow-hidden">
                    {{ 'general.apartment_numberOfFloors_l' | translate }}
                  </span>
                </dt>
                <dd
                  class="col-4 property-expose__details-cell property-expose__details-cell--data"
                >
                  {{ property?.data?.numberOfFloors | appFloor }}
                </dd>
              </dl>
            }
            @if (isNotNullAndUndefined(property?.data?.seniors)) {
              <dl class="property-expose__details-row">
                <dt
                  class="col-8 property-expose__details-cell property-expose__details-cell--title"
                >
                  <svg-icon
                    src="/assets/images/icons/icon-seniors.svg"
                    [applyClass]="true"
                    [svgStyle]="{ 'width.px': 15 }"
                    class="primary-text-svg-color rect line polyline path"
                  ></svg-icon>
                  <span class="u-overflow-hidden">
                    {{ 'general.apartment_seniors_l' | translate }}
                  </span>
                </dt>
                <dd
                  class="col-4 property-expose__details-cell property-expose__details-cell--data"
                >
                  {{ property?.data?.seniors | appHumanizeBoolean }}
                </dd>
              </dl>
            }
            @if (isNotNullAndUndefined(property?.data?.historicBuilding)) {
              <dl class="property-expose__details-row">
                <dt
                  class="col-8 property-expose__details-cell property-expose__details-cell--title"
                >
                  <svg-icon
                    src="/assets/images/icons/icon-monument-protection.svg"
                    [applyClass]="true"
                    [svgStyle]="{ 'width.px': 15 }"
                    class="primary-text-svg-color rect line polyline path"
                  ></svg-icon>
                  <span class="u-overflow-hidden">
                    {{ 'general.apartment_historic_building_l' | translate }}
                  </span>
                </dt>
                <dd
                  class="col-4 property-expose__details-cell property-expose__details-cell--data"
                >
                  {{ property?.data?.historicBuilding | appHumanizeBoolean }}
                </dd>
              </dl>
            }
            @if (isGarage(property)) {
              @if (
                isNotNullAndUndefined(property?.data?.garageData?.freeUntil)
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-calendar.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.free_until_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ property?.data?.garageData?.freeUntil | appDateTime }}
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(
                  property?.data?.garageData?.availableUntil
                )
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-calendar.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.available_until_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      property?.data?.garageData?.availableUntil | appDateTime
                    }}
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(property?.data?.garageData?.garageType)
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-garage.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'property.garage_type_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      'GARAGETYPES_' + property?.data?.garageData?.garageType
                        | translate
                    }}
                  </dd>
                </dl>
              }
              @if (isNotNullAndUndefined(property?.data?.garageData?.width)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-width.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.width_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ property?.data?.garageData?.width }} m
                  </dd>
                </dl>
              }
              @if (isNotNullAndUndefined(property?.data?.garageData?.height)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-height.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.height_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ property?.data?.garageData?.height }} m
                  </dd>
                </dl>
              }
              @if (isNotNullAndUndefined(property?.data?.garageData?.length)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-arrows-diagonal.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.length_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ property?.data?.garageData?.length }} m
                  </dd>
                </dl>
              }
            }
            @if (isCommercial(property)) {
              @if (
                isNotNullAndUndefined(
                  property?.data?.commercialData?.airConditioning
                )
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-calendar.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.air_conditioning_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      property?.data?.commercialData?.airConditioning
                        | appHumanizeBoolean
                    }}
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(
                  property?.data?.commercialData?.hasCanteen
                )
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-width.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.has_canteen_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      property?.data?.commercialData?.hasCanteen
                        | appHumanizeBoolean
                    }}
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(
                  property?.data?.commercialData?.highVoltage
                )
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-arrows-diagonal.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.high_voltage_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      property?.data?.commercialData?.highVoltage
                        | appHumanizeBoolean
                    }}
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(property?.data?.commercialData?.lanCables)
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-arrows-diagonal.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.lan_cables_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      property?.data?.commercialData?.lanCables
                        | appHumanizeBoolean
                    }}
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(
                  property?.data?.commercialData?.distanceToAirport
                )
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-calendar.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.distance_to_airport_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      'general.commercial_distance_in_minutes_l'
                        | translate
                          : {
                              value:
                                property?.data?.commercialData
                                  ?.distanceToAirport
                            }
                    }}
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(
                  property?.data?.commercialData?.distanceToHighway
                )
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-garage.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.distance_to_highway_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      'general.commercial_distance_in_minutes_l'
                        | translate
                          : {
                              value:
                                property?.data?.commercialData
                                  ?.distanceToHighway
                            }
                    }}
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(
                  property?.data?.commercialData?.distanceToPublicTransport
                )
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-height.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.distance_to_public_transport_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      'general.commercial_distance_in_minutes_l'
                        | translate
                          : {
                              value:
                                property?.data?.commercialData
                                  ?.distanceToPublicTransport
                            }
                    }}
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(
                  property?.data?.commercialData?.distanceToTrainStation
                )
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-arrows-diagonal.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.distance_to_train_station_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      'general.commercial_distance_in_minutes_l'
                        | translate
                          : {
                              value:
                                property?.data?.commercialData
                                  ?.distanceToTrainStation
                            }
                    }}
                  </dd>
                </dl>
              }
            }
          </div>
        </div>
        @if (isFlat(property) || isCommercial(property)) {
          <div class="card" [ngClass]="{ 'u-hide': !showInteriorDetailsList }">
            <h2 class="title-m">
              {{ 'general.property_interior_l' | translate }}
            </h2>
            <div #interiorDetailsList>
              @if (property?.data?.parkingSpaces?.length) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-parking.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.apartment_parking_space_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      property?.data?.parkingSpaces?.length | appHumanizeBoolean
                    }}
                  </dd>
                </dl>
              }
              @if (isNotNullAndUndefined(property?.data?.bathRooms)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-bathroom.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.apartment_bathrooms_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ property?.data?.bathRooms }}
                  </dd>
                </dl>
              }

              @if (isNotNullAndUndefined(property?.data?.guestToilette)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-toilet.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.apartment_guest_bathroom_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ property?.data?.guestToilette | appHumanizeBoolean }}
                  </dd>
                </dl>
              }
              @if (isNotNullAndUndefined(property?.data?.kitchenette)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-kitchen.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.apartment_kitchenette_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ property?.data?.kitchenette | appHumanizeBoolean }}
                  </dd>
                </dl>
              }
              @if (isNotNullAndUndefined(property?.data?.tvSatCable)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-tv.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.apartment_tv_sat_cable_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ property?.data?.tvSatCable | appHumanizeBoolean }}
                  </dd>
                </dl>
              }
              @if (isNotNullAndUndefined(property?.data?.garden)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-tree.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.apartment_garden_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ property?.data?.garden | appHumanizeBoolean }}
                  </dd>
                </dl>
              }
              @if (isNotNullAndUndefined(property?.data?.elevator)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-elevator.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.elevator_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ property?.data?.elevator | appHumanizeBoolean }}
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(property?.data?.wheelchairAccessible)
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-barrier-free.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.wheelchair_accessible_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      property?.data?.wheelchairAccessible | appHumanizeBoolean
                    }}
                  </dd>
                </dl>
              }
              @if (isNotNullAndUndefined(property?.data?.shutter)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-shutters.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.shutter_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ property?.data?.shutter | appHumanizeBoolean }}
                  </dd>
                </dl>
              }
              @if (isNotNullAndUndefined(property?.data?.basementAvailable)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-basement.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.basement_available_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ property?.data?.basementAvailable | appHumanizeBoolean }}
                  </dd>
                </dl>
              }
              @if (isNotNullAndUndefined(property?.data?.fireplace)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-fireplace.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.apartment_fireplace_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ property?.data?.fireplace | appHumanizeBoolean }}
                  </dd>
                </dl>
              }
              @if (isNotNullAndUndefined(property?.data?.gardenUse)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-tree.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.apartment_garden_use_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ property?.data?.gardenUse | appHumanizeBoolean }}
                  </dd>
                </dl>
              }
              @if (isNotNullAndUndefined(property?.data?.washDryRoom)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-laundryroom.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.apartment_wash_dry_room_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ property?.data?.washDryRoom | appHumanizeBoolean }}
                  </dd>
                </dl>
              }
              @if (isNotNullAndUndefined(property?.data?.storeRoom)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-storeroom.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.apartment_store_room_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ property?.data?.storeRoom | appHumanizeBoolean }}
                  </dd>
                </dl>
              }

              @if (isNotNullAndUndefined(property?.data?.bicycleRoom)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-bikeroom.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.apartment_bicycle_room_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ property?.data?.bicycleRoom | appHumanizeBoolean }}
                  </dd>
                </dl>
              }
              @if (isNotNullAndUndefined(property?.data?.attic)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-attic.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.apartment_attic_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ property?.data?.attic | appHumanizeBoolean }}
                  </dd>
                </dl>
              }
              @if (isNotNullAndUndefined(property?.data?.numberOfBedrooms)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-bed.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.apartment_bedrooms_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ property?.data?.numberOfBedrooms | appHumanizeBoolean }}
                  </dd>
                </dl>
              }

              @if (isNotNullAndUndefined(property?.data?.numberOfBalconies)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-balcony.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.apartment_balconies_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ property?.data?.numberOfBalconies | appHumanizeBoolean }}
                  </dd>
                </dl>
              }
              @if (isNotNullAndUndefined(property?.data?.numberOfTerraces)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-terrace.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.apartment_terraces_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ property?.data?.numberOfTerraces | appHumanizeBoolean }}
                  </dd>
                </dl>
              }
              @if (isNotNullAndUndefined(property?.data?.numberOfLoggias)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-loggia.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.number_of_loggias_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ property?.data?.numberOfLoggias | appHumanizeBoolean }}
                  </dd>
                </dl>
              }
              @if (isNotNullAndUndefined(property?.data?.balconyTerraceArea)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-room-size.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{
                        'general.apartment_balcony_terrace_area_l' | translate
                      }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      property?.data?.balconyTerraceArea | appHumanizeBoolean
                    }}
                    <span>m<sup>2</sup></span>
                  </dd>
                </dl>
              }
              @if (isNotNullAndUndefined(property?.data?.landArea)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-room-size.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.land_area_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ property?.data?.landArea | appHumanizeBoolean }}
                    <span>m<sup>2</sup></span>
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(property?.data?.washingMachineConnection)
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-washingmachine.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.washing_machine_connection_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      property?.data?.washingMachineConnection
                        | appHumanizeBoolean
                    }}
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(property?.data?.bathroomEquipment?.shower)
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-shower.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.bathroom_shower_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      property?.data?.bathroomEquipment?.shower
                        | appHumanizeBoolean
                    }}
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(
                  property?.data?.bathroomEquipment?.bathtub
                )
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-bathroom.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.bathroom_bathtub_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      property?.data?.bathroomEquipment?.bathtub
                        | appHumanizeBoolean
                    }}
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(property?.data?.bathroomEquipment?.window)
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-bathroom.window.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.bathroom_window_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      property?.data?.bathroomEquipment?.window
                        | appHumanizeBoolean
                    }}
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(property?.data?.bathroomEquipment?.bidet)
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-bidet.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.bathroom_bidet_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      property?.data?.bathroomEquipment?.bidet
                        | appHumanizeBoolean
                    }}
                  </dd>
                </dl>
              }
              @if (
                isNotNullAndUndefined(property?.data?.bathroomEquipment?.urinal)
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-urinal.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.bathroom_urinal_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{
                      property?.data?.bathroomEquipment?.urinal
                        | appHumanizeBoolean
                    }}
                  </dd>
                </dl>
              }
              @if (isNotNullAndUndefined(property?.data?.intercomType)) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-intercom.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'general.intercom_l' | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 p0 property-expose__details-cell property-expose__details-cell--data text-capitalize"
                  >
                    {{
                      property?.data?.intercomType
                        | titlecase
                        | appHumanizeBoolean
                    }}
                  </dd>
                </dl>
              }
              @if (property?.customerDefinedFieldValues?.length) {
                @for (
                  fieldValue of property.customerDefinedFieldValues;
                  track fieldValue.definition.id
                ) {
                  @if (isNotNullAndUndefined(fieldValue.value.value)) {
                    <dl class="property-expose__details-row">
                      <dt
                        class="col-8 property-expose__details-cell property-expose__details-cell--title"
                      >
                        <span class="u-overflow-hidden pl20">
                          {{ fieldValue.definition.label }}
                        </span>
                      </dt>
                      <dd
                        class="col-4 p0 property-expose__details-cell property-expose__details-cell--data text-capitalize"
                      >
                        {{
                          fieldValue.value.type === 'DATE'
                            ? (fieldValue.value.value | appDateTime)
                            : (fieldValue.value.value | appHumanizeBoolean)
                        }}
                      </dd>
                    </dl>
                  }
                }
              }
            </div>
          </div>
        }
        @if (
          (isFlat(property) || isCommercial(property)) &&
          property?.data?.parkingSpaces?.length
        ) {
          <div class="card">
            <h2 class="title-m">
              {{ 'general.parking_spaces_l' | translate }}
            </h2>
            <div>
              <dl class="property-expose__details-row">
                <dt
                  class="col-8 property-expose__details-cell property-expose__details-cell--title"
                >
                  {{ 'general.parking_space_type_l' | translate }}
                </dt>
                <dt
                  class="col-4 property-expose__details-cell property-expose__details-cell--data"
                >
                  {{ 'general.parking_space_count_l' | translate }} /
                  {{ 'general.parking_places_cost_per_space_l' | translate }}
                </dt>
              </dl>
              @for (
                parkingSpace of property?.data?.parkingSpaces;
                track parkingSpace.type;
                let index = $index
              ) {
                <dl class="property-expose__details-row">
                  <dt
                    class="col-8 property-expose__details-cell property-expose__details-cell--title"
                  >
                    <svg-icon
                      src="/assets/images/icons/icon-parking.svg"
                      [applyClass]="true"
                      [svgStyle]="{ 'width.px': 15 }"
                      class="primary-text-svg-color rect line polyline path"
                    ></svg-icon>
                    <span class="u-overflow-hidden">
                      {{ 'PARKINGSPACETYPES_' + parkingSpace.type | translate }}
                    </span>
                  </dt>
                  <dd
                    class="col-4 property-expose__details-cell property-expose__details-cell--data"
                  >
                    {{ parkingSpace.count || 0 }} /
                    {{
                      (isSales(property?.marketingType)
                        ? parkingSpace.purchasePrice
                        : parkingSpace.price) || 0 | currency: 'EUR'
                    }}
                  </dd>
                </dl>
              }
            </div>
          </div>
        }
      </div>
      @if (!property?.rented) {
        @if (!isSales(property?.marketingType)) {
          <div class="d-flex row mt30">
            @if (isProposal(propertyMatch) || isApplyPage) {
              <div class="col-12 col-md-6">
                <div class="property-expose__privacy-section">
                  <div class="title-m mb8">
                    {{ 'property.apply.privacy_header' | translate }}
                  </div>
                  <div
                    [innerHTML]="'property.apply.privacy_message' | translate"
                  ></div>
                  @if (property?.dataPrivacyUrl) {
                    <br />
                    <a
                      [href]="property.dataPrivacyUrl"
                      target="_blank"
                      class="me-1 link blue"
                      >{{ 'property.apply.privacy_link_l' | translate }}</a
                    >
                    <span
                      >{{ 'property.apply.privacy_link_text' | translate }}
                    </span>
                  }
                </div>
              </div>
            }
            <div class="col-12 col-md-6">
              <div class="property-expose__privacy-section">
                <div class="title-m mb8">
                  {{ 'schufa.schufa_info' | translate }}
                </div>
                <app-embedded-link
                  [contentKey]="'schufa.schufa_reports_information'"
                  [linkTarget]="'/static/schufa-info'"
                  [linkTextKey]="'schufa.info_page_link'"
                >
                </app-embedded-link>
              </div>
            </div>
          </div>
        } @else {
          <div class="sales-legal-preview card-columns">
            <div
              class="card flex-1 sales-legal-preview__content"
              [innerHTML]="'sales_legal_text.explanation_l' | translate"
            ></div>
            @if (property?.salesLegalTexts?.length) {
              <div class="card flex-1 sales-legal-preview__content">
                <app-sales-legal-checkboxes-preview
                  [salesLegalTexts]="property.salesLegalTexts"
                  (allCheckboxesChecked)="
                    updateSalesLegalTextCheckboxesValid($event)
                  "
                ></app-sales-legal-checkboxes-preview>
              </div>
            }
          </div>
        }
        @if (isApplyPage) {
          <div class="property-expose__links">
            @for (link of links; track link.name) {
              <span>
                <a class="me-3" href="{{ link.url }}" target="_blank">{{
                  link.name | translate
                }}</a>
              </span>
            }
          </div>
        }
      }
    </div>
    @if (showActionBar) {
      <app-application-actions
        [propertyMatch]="propertyMatch"
        [property]="property"
        [disabled]="isProcessing"
        [isApplyPage]="isApplyPage"
        [salesLegalTextCheckboxesValid]="
          !isSales(property?.marketingType) ||
          salesLegalTextCheckboxesValid ||
          !hasSalesCheckboxes
        "
        (landingApply)="onLandingApply()"
        (applyAnonymous)="onApplyAnonymous($event)"
        (openSalesModal)="onOpenSalesModal()"
      ></app-application-actions>
    }
  </div>
} @else {
  <div class="property-expose__body modal-body modal__body--loading">
    <div class="loading-area">
      <app-loading-spinner></app-loading-spinner>
    </div>
  </div>
}
