import { GetSchufaComponent } from './get-schufa/get-schufa.component';
import {
  SchufaPersonalFormComponent,
  SchufaPaymentOrderComponent,
  SchufaIdentificationComponent,
  ConfirmationInfoComponent,
  SchufaDeliveryComponent,
  SchufaFinishComponent,
  SchufaAddressComponent,
  SchufaSuccessModalComponent,
  SchufaBenefitsCardComponent,
  SchufaCertificatesComponent
} from './components';
import { SchufaWizardComponent } from './schufa-wizard/schufa-wizard.component';

export { SchufaSuccessModalComponent } from './components';
export { BaseSchufaStep } from './base-schufa-step';

export const schufaComponents = [
  SchufaWizardComponent,
  GetSchufaComponent,
  SchufaPersonalFormComponent,
  SchufaPaymentOrderComponent,
  SchufaIdentificationComponent,
  ConfirmationInfoComponent,
  SchufaDeliveryComponent,
  SchufaFinishComponent,
  SchufaAddressComponent,
  SchufaSuccessModalComponent,
  SchufaBenefitsCardComponent,
  SchufaCertificatesComponent
];
