import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { ComponentsModule } from 'libs/components';

@Component({
  selector: 'app-get-schufa',
  templateUrl: './get-schufa.component.html',
  styleUrls: ['./get-schufa.component.scss'],
  standalone: true,
  imports: [ComponentsModule, SvgIconComponent, TranslateModule]
})
export class GetSchufaComponent {
  @Output() goToSchufa = new EventEmitter();

  public onGoToSchufa() {
    this.goToSchufa.emit();
  }
}
