<div class="moving-auction-card">
  <div class="card w-100 h-100 m0">
    <div class="moving-auction-card__badge-container">
      <app-badge [color]="'neutral-light-dark'" [icon]="'coins'">{{
        'moving_auction.card.badge_text_l' | translate
      }}</app-badge>
    </div>

    @if (showNewMarker) {
      <div class="moving-auction-card__notification-bubble">
        <svg-icon
          src="/assets/images/icons/icon-new.svg"
          [applyClass]="true"
          [svgStyle]="{ 'height.px': 35 }"
          class="white-svg-color circle text"
        ></svg-icon>
      </div>
    }

    <app-button
      class="moving-auction-card__info-btn default default-secondary btn--icon d-flex justify-content-center align-item-center"
      [type]="'primary'"
      (clickEvent)="onGoToInfo()"
    >
      <svg-icon
        src="/assets/images/icons/icon-questionmark.svg"
        [applyClass]="true"
        [svgStyle]="{ 'height.px': 20, 'width.px': 20 }"
      ></svg-icon>
    </app-button>

    <div class="card-body pt50 d-flex flex-column">
      <div class="row pb15">
        <div class="col moving-auction-card__title">
          {{ 'moving_auction.card.title' | translate }}
        </div>
      </div>
      <p
        class="moving-auction-card__text mt0 mb-auto"
        [innerHTML]="'moving_auction.card.body' | translate"
      ></p>

      <div
        class="row moving-auction-card__cooperation d-flex align-items-center"
      >
        <div class="col-5 moving-auction-card__text">
          {{ 'general.in_cooperation_with' | translate }}
        </div>
        <div class="col-7">
          <img
            class="immomio-logo"
            src="/assets/images/logos/umzugsauktion_logo.svg"
            alt="umzugsauktion – Wir bewegen was."
          />
        </div>
      </div>

      <app-button (clickEvent)="onClickMovingAuction()" [type]="'primary'">{{
        'moving_auction.card.start_wizard' | translate
      }}</app-button>
    </div>
  </div>
</div>
