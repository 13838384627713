import { ContractPersonalInformationComponent } from './components/contract-personal-information/contract-personal-information.component';
import { ContractViewingComponent } from './components/contract-viewing/contract-viewing.component';
import { ContractIdVerificationComponent } from './components/contract-id-verification/contract-id-verification.component';
import { ContractCodeVerificationComponent } from './components/contract-code-verification/contract-code-verification.component';
import { ContractSigningComponent } from './components/contract-signing/contract-signing.component';
import { SignContractComponent } from './sign-contract-wizard/sign-contract.component';

export const digitalContractComponents = [
  SignContractComponent,
  ContractPersonalInformationComponent,
  ContractViewingComponent,
  ContractIdVerificationComponent,
  ContractCodeVerificationComponent,
  ContractSigningComponent
];
