import { MovingAuctionAddressComponent } from './moving-auction-address/moving-auction-address.component';
import { MovingAuctionContactComponent } from './moving-auction-contact/moving-auction-contact.component';
import { MovingAuctionWizardComponent } from './moving-auction-wizard.component';
import { MovingAuctionCardComponent } from './moving-auction-card/moving-auction-card.component';
import { MovingAuctionDataComponent } from './moving-auction-data/moving-auction-data.component';

export const movingAuctionComponents = [
  MovingAuctionWizardComponent,
  MovingAuctionAddressComponent,
  MovingAuctionDataComponent,
  MovingAuctionContactComponent,
  MovingAuctionCardComponent
];
