<app-modal-v2>
  <div class="modal-m">
    <app-modal-content>
      <div class="modal-body">
        <div class="d-flex justify-content-between">
          <div class="title-m payment-positions-request-feedback-modal__title">
            {{ 'payment_request.give_feedback' | translate }}
          </div>
        </div>
        <p class="mt15">
          {{ 'payment_request.partial_pay_question_m' | translate }}
        </p>
        <div class="mt10">
          <app-button [type]="'light-bordered'" (clickEvent)="onClose(true)">{{
            'payment_request.already_paid_a' | translate
          }}</app-button>
        </div>
        <div class="mt10 mb5">
          <app-button (clickEvent)="onClose(false)">{{
            'payment_request.pay_later_a' | translate
          }}</app-button>
        </div>
      </div>
    </app-modal-content>
  </div>
</app-modal-v2>
