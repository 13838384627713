<div class="row m0 intent-buttons__container">
  <app-button
    [disabled]="disabled"
    (clickEvent)="onSetIntent(false)"
    class="col-6 pr0 pl5 not-intent mr5"
    [type]="'negative'"
  >
    {{ 'application.not_interested_a' | translate }}
  </app-button>
  <app-button
    [disabled]="disabled"
    (clickEvent)="onSetIntent(true)"
    class="col-6 pl0 pr5 intent ml5"
    [type]="'positive'"
  >
    {{ 'application.interested_a' | translate }}
  </app-button>
</div>
